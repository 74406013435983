import React from "react"
import { graphql } from "gatsby"

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"

import Layout from "../components/layout"

import { FBandInsta } from "../components/socialmenu"

import { Grid, ContentBox, H2, P, Ol, Oi } from "../components/styledcomponents"
import globals from "../components/globals"

const ListElement = styled.div`
  position: relative;
  left: 2.5em;
  top: -1.5em;
  padding-right: 2em;
`

const breatColor = "#3b6885"

const BreathworkThanks = ({ data }) => {
  return (
    <Layout>
      <Grid
        backgroundColor="transparent"
        padding="4em 0"
        columns="1fr 3fr 1fr"
        rows="repeat(5, 1fr)"
      >
        <ContentBox
          backgroundColor={globals.color.main}
          style={{ gridColumn: "1/4", gridRow: "2/3" }}
          maxWidth="100%"
          width="100%"
          radius="0"
        >
          <p>orange bar</p>
        </ContentBox>
        <ContentBox
          backgroundColor="white"
          style={{ gridColumn: "2/3", gridRow: "1/6" }}
          maxWidth="100%"
          width="100%"
        />
        <ContentBox
          backgroundColor={breatColor}
          background={`linear-gradient(${breatColor}50 0%, ${breatColor}ff 100%)`}
          style={{ gridColumn: "2/3", gridRow: "1/6" }}
          maxWidth="100%"
          width="100%"
          radius="25px"
          shadow
          border="2px solid white"
        >
          <H2 color="white">Dein Atemuebungs-Guide ist auf dem Weg zu Dir!</H2>
          <Grid columns="2fr 1fr" alignItems="center">
            <Ol color="white">
              <Oi>
                <ListElement>Bitte schau in Deine Emails</ListElement>
              </Oi>
              <Oi>
                <ListElement>
                  Solltest Du innerhalb von 30 Minuten keine Email erhalten
                  suche bitte in Deinem Spamordner
                </ListElement>
              </Oi>
              <Oi>
                <ListElement>
                  Um sicher zu stellen, dass Du keine Gesundheitstipps und
                  Angebote von uns verpasst füge Bitte bjj@team-laurien-alex.de
                  zu Deinem Adressbuch hinzu
                </ListElement>
              </Oi>
            </Ol>
            <ContentBox width="100%">
              <GatsbyImage image={data.magnet.childImageSharp.gatsbyImageData} />
            </ContentBox>
          </Grid>
        </ContentBox>
      </Grid>
      <ContentBox shadow margin="4em auto" maxWidth="800px">
        <P center>
          CHECK OUT OUR <Link to="/blog/">BLOG</Link> & SOCIAL CHANNELS FOR MORE
          WELLBEING TIPS AND ARTICLES <FBandInsta color={globals.color.main} />
        </P>
      </ContentBox>
    </Layout>
  );
}
export const query = graphql`{
  magnet: file(relativePath: {eq: "Cover_Bist_du_gestresst_atemuebungen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
}
`
export default BreathworkThanks
